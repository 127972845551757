<template>
	<a-card :bordered="false" class="widget-2 h-full mb-15 select-os">
		<div class="ant-statistic" @click="selectFirst">
			<div class="ant-statistic-title">
				<div :class="`icon custom-icon ${icon} ${active ? '' : 'filter-grayscale'}`"></div>
				<p>{{ title }}</p>
			</div>
			<div class="ant-statistic-content">
				<a-select 
					v-model="id"
					style="width: 100%" 
					:options="rows" 
					:placeholder="$t('version')" 
				/>
			</div>
		</div>
	</a-card>
</template>

<script>
	
	export default ({
		props: {
			rows: {
				type: Array,
				default: ()=> {return []},
			},
			title: {
				type: String,
				default: ''
			},
			icon: {
				type: String,
				default: ''
			},
			value: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				active: false,
				id: undefined
			}
		},
		watch: {
			value : function (val) {
				if (val === this.id) return
				this.id 	= undefined
				this.active = false
			},
			id : function (val) {
				if (val === undefined || val == '') return
				this.$emit('input', val)
			}
		},
		methods: {
			selectFirst() {
				if (this._.isEmpty(this.rows)) return
				this.active = true
				this.id = this._.first(this.rows).value
			}
		}
	})

</script>