<template>
	<a-card :bordered="false" class="header-solid" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">
		<template #title>
			<h6 class="font-semibold m-0">Giao dịch gần đây</h6>
		</template>
		<p v-if="!_.isEmpty(rows)" slot="extra" class="card-header-date">
			<router-link to="/payment" class="p-0">{{$t('viewAll')}}</router-link>
			<!-- <a-button type="link" class="p-0" :href="">Xem thêm</a-button> -->
		</p>
		<a-list
			class="transactions-list"
			item-layout="horizontal"
			:split="false"
			:data-source="rows"
			v-if="!fetchTransactions"
		>
			<a-list-item slot="renderItem" slot-scope="item">
				<a-list-item-meta
					:title="item.type_label"
					:description="item.created_at"
				>
					<a-avatar size="small" v-if="item.type == WALLET_LOG_UP" slot="avatar" style="background-color: #EDF9E7">
						<svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-success" fill-rule="evenodd" clip-rule="evenodd" d="M10 3C10.5523 3 11 3.44772 11 4V9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11H4C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9L9 9V4C9 3.44772 9.44772 3 10 3Z"/>
						</svg>
					</a-avatar>
					<a-avatar size="small" v-if="item.type == WALLET_LOG_CREATE" slot="avatar" style="background-color: #FFFCE7">
						<strong class="text-warning">
							!
						</strong>
					</a-avatar>
					<a-avatar size="small" v-if="item.type == WALLET_LOG_DOWN" slot="avatar" style="background-color: #FEE9EA">
						<svg width="10" height="10" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-danger" fill-rule="evenodd" clip-rule="evenodd" d="M5 10C5 9.44772 5.44772 9 6 9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11L6 11C5.44772 11 5 10.5523 5 10Z"/>
						</svg>
					</a-avatar>
				</a-list-item-meta>
				<div class="amount">
					<span v-if="item.type == WALLET_LOG_UP" class="text-success">
						+ {{ currency(item.value) }}
					</span>
					<span v-if="item.type == WALLET_LOG_CREATE" class="text-warning">
						0
					</span>
					<span v-if="item.type == WALLET_LOG_DOWN" class="text-danger">
						{{ currency(item.value) }}
					</span>
				</div>
			</a-list-item>
		</a-list>
		<a-spin v-else tip="Loading..."></a-spin>
		<a-pagination v-if="more"
		v-model:current="currentPage" 
		:defaultPageSize="pageSize"
		:total="total" 
		show-less-items 
		@change="onPageChange"/>
	</a-card>
</template>

<script>
	import transactions from "@/services/user/transactions"
	import {WALLET_LOG_CREATE, WALLET_LOG_UP, WALLET_LOG_DOWN} from "@/constant"
	import {currency} from "@/helper"

	export default ({
		data() {
			return {
				rows: [],
				WALLET_LOG_CREATE,
				WALLET_LOG_UP,
				WALLET_LOG_DOWN,
				currency,
				fetchTransactions: true,
				currentPage : 1,
				pageSize : 15,
				total : 0
			}
		},
		props : ['more'],
		created() {
			this.getData()
		},
		methods:{
			getData(){
				transactions(this.currentPage)
				.then(res=> {
					this.fetchTransactions = false
					if (res.status == 1) {
						this.rows = res.data.data
						this.total = res.data.total
					}
				})
			},
			onPageChange (page) {
	        	this.currentPage = page
	        	this.getData()
	        },
		}
	})

</script>