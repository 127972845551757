<template>
	<a-row type="flex" :gutter="24">
        <a-col :span="24" :md="17" class="mb-20">
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <a-row type="flex" align="middle">
                        <a-col :span="24" :md="12">
                            <h6 class="font-semibold m-0">1. {{ $t('server.actionCreate.titleSelectOs') }}</h6>
                        </a-col>
                        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                            <a-radio-group :value="boot_type" size="small">
                                <a-radio-button @click="changeBootType(BOOT_TYPE_IMAGE)" :value="BOOT_TYPE_IMAGE">{{ $t('server.actionCreate.osImage') }}</a-radio-button>
                                <a-radio-button @click="changeBootType(BOOT_TYPE_SNAPSHOT)" :value="BOOT_TYPE_SNAPSHOT">Bootable Snapshot</a-radio-button>
                            </a-radio-group>
                        </a-col>
                    </a-row>
                </template>
                <WidgetSelectOs v-show="boot_type === BOOT_TYPE_IMAGE" v-model="form.info.image_id" />
                <div v-if="boot_type === BOOT_TYPE_SNAPSHOT">
                    <a-select style="width: 100%"
                        v-model="form.info.snapshot_id"
                        :loading="fetchSnapshot" 
                        :options="snapshots"
                        placeholder="Chọn bản Snapshot"
                    />
                </div>
            </a-card>
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <h6 class="font-semibold m-0">2. {{ $t('server.actionCreate.titleConfig') }}</h6>
                </template>
                <WidgetSliderConfig 
                    v-model="form.config"
                />
            </a-card>
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <h6 class="font-semibold m-0">3. {{ $t('server.actionCreate.paymentTerm') }}</h6>
                </template>
                <WidgetSelectTerm
                    :params="form.config"
                    :type="form.type"
                    v-model="WidgetSelectTermData"
                />
            </a-card>
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <h6 class="font-semibold m-0">4. {{ $t('server.actionCreate.serverInfo.title') }}</h6>
                </template>
                <a-form :form="serverInfo" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-alert class="mb-10" show-icon :message="$t('server.actionCreate.serverInfo.desc')" type="info" />
                    <a-alert class="mb-10" show-icon :message="$t('server.actionCreate.loginInfo.txtWarningSshKey')" type="warning" />
                    <a-form-item :label="$t('server.actionCreate.serverInfo.name')">
                    <a-input
                        v-decorator="['name', { rules: rules.name }]"
                    />
                    </a-form-item>
                    <a-form-item label="Network">
                        <a-select
                            :options="networks"
                            v-decorator="['network_id', { rules: [{ required: true, message: 'Bạn chưa chọn network!' }] }]"
                        >
                        </a-select>
                    </a-form-item>
                    <a-form-item label="Security Group">
                        <a-select
                            :options="securityGroups"
                            v-decorator="['security_group', { rules: [{ required: true, message: 'Bạn chưa chọn security group!' }] }]"
                        >
                        </a-select>
                    </a-form-item>
                    <a-form-item label="SSH Key">
                        <a-select
                            v-decorator="['ssh_key']"
                            :options="sshKeys"
                            :placeholder="$t('server.actionCreate.serverInfo.sshKey.placeHolder')"
                        >
                        </a-select>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                        <a-button 
                            type="primary" 
                            html-type="submit"
                            :loading="fetchCreateOrder"
                            >{{ $t('server.actionCreate.title') }}
                        </a-button>
                    </a-form-item> 
                </a-form>
            </a-card>
        </a-col>
		<a-col :span="24" :md="7" class="mb-24">
		</a-col>
	</a-row>
</template>

<script>

	import CardTransactions from "@/components/Cards/CardTransactions"
    import WidgetSelectOs from "@/components/Widgets/WidgetSelectOs"
    import WidgetSliderConfig from "@/components/Widgets/WidgetSliderConfig"
    import WidgetSelectTerm from "@/components/Widgets/WidgetSelectTerm"
    import OrderService from '@/services/order'
    import { notification } from 'ant-design-vue'
    import networks from "@/services/network/listing"
    import securityGroups from "@/services/network/securityGroups"
    import sshKeys from "@/services/server/ssh_keys"
    import {PATTERN_NAME, BOOT_TYPE_IMAGE, BOOT_TYPE_SNAPSHOT, SNAPSHOT_ACTIVE, ORDER_TYPE_INSTANCE, STORAGE_UNIT} from "@/constant"
    import snapshots from "@/services/snapshot/listing"

	export default ({
		components: {
			CardTransactions,
            WidgetSelectOs,
            WidgetSliderConfig,
            WidgetSelectTerm,
		},
		data() {
			return {
                BOOT_TYPE_IMAGE,
                BOOT_TYPE_SNAPSHOT,
                SNAPSHOT_ACTIVE,
                form: {
                    type: ORDER_TYPE_INSTANCE,
                    config: {},
                    info: {
                        image_id: '',
                        snapshot_id: undefined,
                    },
                    interval: ''
                },
                rules: {
                    name: [
                        {required: true, message: this.$t('rulesForm.requiredServerName'), trigger: ['blur', 'change']},
                        {min: 3, max: 32, message: this.$t('rulesForm.invalidServerCharactersLimited'), trigger: ['blur', 'change']},
                        { pattern: PATTERN_NAME,
                        message: this.$t('rulesForm.invalidServerName'),
                        trigger: ['blur', 'change']
                        }
                    ]
                },
                boot_type: BOOT_TYPE_IMAGE,
                serverInfo: this.$form.createForm(this, { name: 'server_info' }),
                WidgetSelectTermData: {},
                fetchCreateOrder: false,
                fetchSnapshot: true,
                networks: [],
                securityGroups: [],
                sshKeys: [],
                snapshots: [],
			}
		},
        watch: {
            WidgetSelectTermData: function (val) {
                this.form.interval = val.interval
            }
        },
        created() {
			networks()
			.then(res=> {
				if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        if (row.name == 'default')
                            this.serverInfo.setFieldsValue({
                                network_id: row.id
                            })
                        this.networks.push({
                            label: row.name,
                            value: row.id
                        })
                    })
				}
			})
            securityGroups()
			.then(res=> {
				if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        if (row.name == 'default')
                            this.serverInfo.setFieldsValue({
                                security_group: row.name
                            })

                        this.securityGroups.push({
                            label: row.name,
                            value: row.name
                        })
                    })
				}
			})
            sshKeys()
			.then(res=> {
				if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        this.sshKeys.push({
                            label: row.name,
                            value: row.name
                        })
                    })
				}
			})
            snapshots()
			.then(res=> {
				if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        if (row.status !== SNAPSHOT_ACTIVE || !row.volume.is_rootdisk) return
                        this.snapshots.push({
                            label: `${row.name} (${row.volume.name} / ${row.volume.volume_type.toUpperCase()} ${row.size} ${STORAGE_UNIT})`,
                            value: row.id
                        })
                    })
                    this.fetchSnapshot = false
				}
			})
		},
        methods : {
            changeBootType(type) {
                this.boot_type = type
                switch (type) {
                    case BOOT_TYPE_IMAGE:
                        this.form.info.snapshot_id = undefined
                    break;

                    case BOOT_TYPE_SNAPSHOT:
                        this.form.info.image_id = ''
                    break;
                }
            },
            handleSubmit(e) {
                e.preventDefault();
                this.serverInfo.validateFields((err, values) => {
                    if (err) {
                        return;
                    }
                    console.log(this.form)
                    this.form.info.name = values.name
                    this.form.info.network_id           = values.network_id
                    this.form.info.security_group    = values.security_group
                    this.form.info.ssh_key    = values.ssh_key

                    let messages = []

                    if (this._.isEmpty(this.form.info.snapshot_id) && this._.isEmpty(this.form.info.image_id))
                        messages.push('Bạn chưa chọn bộ cài')
                    
                    if (this.form.config.cpu < 1 || this.form.config.ram < 1 || this.form.config.disk < 1)
                        messages.push('Bạn chưa chọn cấu hình')
                    
                    if (this.form.interval === '')
                        messages.push('Bạn chưa chọn kỳ hạn thanh toán')
                    
                    if (!this._.isEmpty(messages)) {
                        this._.forEach(messages, val => {
                            notification.error({
                                message: val
                            })
                        })
                        return
                    }

                    this.createOrder()
                });
            },
            createOrder() {
                this.fetchCreateOrder       = true
                this.form.info.snapshot_id  = this.form.info.snapshot_id === undefined ? '' : this.form.info.snapshot_id
                OrderService.create(this.form)
                .then(res => {
                    this.fetchCreateOrder = false
                    if (this._.isObject(res) && res.status == 1) {
                        notification.success({
                            message: 'Tạo đơn hàng thành công'
                        })
                        this.$router.push({name: 'billing.payment', params: {id: res.data.invoice_id}})
                    } else {
                        notification.error({
                            message: res.message
                        })
                    }
                })
            }
        }
	})

</script>

<style lang="scss">
</style>