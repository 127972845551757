<template>
	<div>
		<template v-if="!fetchLoading">
			<a-row :gutter="24">
				<a-col v-for="row in images" :lg="6" :span="12">
					<CardOS
						:icon="row.icon"
						:title="row.label"
						:rows="row.rows"
						v-model="id"
					/>
				</a-col>
			</a-row>
		</template>
		<a-spin v-else tip="Loading..."></a-spin>
	</div>
</template>

<script>
	import CardOS from "@/components/Cards/CardOS"
	import ProductService from '@/services/product'

	export default ({
		components: {
            CardOS
		},
		props: {
			value: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				fetchLoading: true,
				images: [],
				id: ''
			}
		},
		created () {
			ProductService.images()
			.then(res => {
				if (this._.isObject(res)) {
					this.fetchLoading = false
					this.images = this._.get(res, 'data', [])
				}
			})
		},
		watch: {
			id : function (val) {
				this.$emit('input', val)
			},
			value : function (val) {
				this.id = val
			}
		}
	})

</script>