<template>
	<div>
		<template v-if="!fetchLoading">
			<template v-if="show.cpu">
				<div class="text-dark">Core CPU</div>
				<a-row :gutter="24" v-if="!_.isEmpty(range.cpu)">
					<a-col :sm="18" :xs="14">
						<a-slider 
							:marks="range.cpu" 
							:min="_.first(options.cpu).value" :max="_.last(options.cpu).value" 
							:step="null" :dots="true" v-model:value="cpu" :tip-formatter="formatterCpu" />
					</a-col>
					<a-col :sm="6" :xs="8">
						<a-select
							v-model:value="cpu"
							:options="options.cpu"
							size="small"
							class="mt-5 ml-5"
							style="width: 90px;"
						/>
					</a-col>
				</a-row>
				<!-- <a-radio-group v-model:value="cpu_type" class="mb-15 mt-10">
					<span class="pr-10">Loại CPU: </span> 
					<a-radio :value="TYPE_CPU">Basic</a-radio>
					<a-radio :value="TYPE_CPU_CALCULUS">Calculus</a-radio>
					<a-radio :value="TYPE_CPU_GRAPHIC">Graphic</a-radio>
				</a-radio-group> -->
			</template>
			<template v-if="show.ram">
				<div class="text-dark">RAM ({{STORAGE_UNIT}})</div>
				<a-row :gutter="24" v-if="!_.isEmpty(range.ram)">
					<a-col :sm="18" :xs="14">
						<a-slider 
							:marks="range.ram" 
							:min="_.first(options.ram).value" :max="_.last(options.ram).value" 
							:step="null" 
							:dots="true" 
							v-model:value="ram" 
							:tip-formatter="formatterRam" 
						/>
					</a-col>
					<a-col :sm="6" :xs="8">
						<a-select
							v-model:value="ram"
							:options="options.ram"
							size="small"
							class="mt-5 ml-5"
							style="width: 90px;"
						/>
					</a-col>
				</a-row>
			</template>
			<template v-if="show.disk">
				<div class="text-dark">Disk ({{STORAGE_UNIT}})</div>
				<a-row :gutter="24">
					<a-col :sm="18" :xs="14">
						<a-slider :min="range.disk.min" :max="range.disk.max" :step="range.disk.step"  v-model:value="disk" :tip-formatter="formatterDisk" />
					</a-col>
					<a-col :sm="6" :xs="8">
						<a-input-number size="small" :step="range.disk.step" :min="range.disk.min" :max="range.disk.max" v-model:value="disk" class="ml-5" />
					</a-col>
				</a-row>
				<a-radio-group v-model:value="disk_type">
					<span class="pr-10">Loại ổ cứng: </span> <a-radio :value="DISK_TYPE_SSD">{{DISK_TYPE_SSD.toUpperCase()}}</a-radio>
				</a-radio-group>
			</template>
		</template>
		<a-spin v-else tip="Loading..."></a-spin>
	</div>
</template>

<script>
	import ProductService from '@/services/product'
	import {DISK_TYPE_SSD, STORAGE_UNIT, TYPE_CPU, TYPE_CPU_CALCULUS, TYPE_CPU_GRAPHIC} from "@/constant"

	export default ({
		props: {
			value: {
				type: Object,
				default: ()=> {return {}},
			},
			show: {
				type: Object,
				default: ()=> {return {
					cpu: true,
					ram: true,
					disk: true,
				}},
			},
			range_custom: {
				type: Object,
				default: ()=> {return {}},
			}
		},
		data() {
			return {
				DISK_TYPE_SSD,
				STORAGE_UNIT,
				TYPE_CPU,
				TYPE_CPU_CALCULUS,
				TYPE_CPU_GRAPHIC,
				fetchLoading: true,
				ram: 0,
				disk: 0,
				cpu: 0,
				cpu_type: TYPE_CPU,
				disk_type: DISK_TYPE_SSD,
				range: {
					ram: {},
					cpu: {},
					disk: {
						min: 0,
						max: 0,
						step: 10
					}
				},
				options: {
					ram: [],
					cpu: [],
				}
			}
		},
		created () {
			ProductService.config()
			.then(res => {
				if (this._.isObject(res)) {
					this.fetchLoading = false
					this._.forEach(this._.get(res, 'data.range_ram', []), value => {
						this.options.ram.push({value: value, label: value +' '+ STORAGE_UNIT})
						this.range.ram[value] = value
					});
					this._.forEach(this._.get(res, 'data.range_cpu', []), value => {
						this.options.cpu.push({value: value, label: value})
						this.range.cpu[value] = value
					});
					this.range.disk.min = this._.get(res, 'data.disk_min', 0)
					this.range.disk.max = this._.get(res, 'data.disk_max', 0)

					if ('disk_min' in this.range_custom && this.range_custom.disk_min > 0)
						this.range.disk.min = this.range_custom.disk_min

					// Lấy giá trị nhỏ nhất của cấu hình
					// Nếu truyền mặc định thì k chạy nữa
					if (this.value.ram > 0 || this.value.disk > 0 || this.value.cpu > 0) return
					this.ram = this._.get(res, 'data.range_ram[0]', 0)
					this.cpu = this._.get(res, 'data.range_cpu[0]', 0)
					this.disk = this._.get(res, 'data.disk_min', 0)
				}
			})
		},
		watch: {
			value : function (val) {
				this.ram = val.ram
				this.disk = val.disk
				this.cpu = val.cpu
				this.disk_type = val.disk_type
			},
			ram : function () {
				this.updateValue()
			},
			disk : function () {
				this.updateValue()
			},
			cpu : function () {
				this.updateValue()
			},
			disk_type : function () {
				this.updateValue()
			},
			cpu_type : function () {
				this.updateValue()
			}
		},
		methods: {
			updateValue() {
				this.$emit('input', {
					ram: this.ram,
					disk: this.disk,
					cpu: this.cpu,
					disk_type: this.disk_type,
					cpu_type: this.cpu_type,
				});
			},
			formatterCpu(val) {
				return `${val} Core CPU`;
			},
			formatterDisk(val) {
				return `${val} ${STORAGE_UNIT} Disk`;
			},
			formatterRam(val) {
				return `${val} ${STORAGE_UNIT} RAM`;
			}
		}
	})

</script>